import React from "react"

import * as Accordion from "@radix-ui/react-accordion"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ProductFlowChart from "../images/product/flowchart.svg"

import EngagementAutomation from "../images/landing/engagement.png"
import TargetingAutomation from "../images/landing/targeting.png"
import DemoSplash from "../images/product/demo-splash.png"
import OptimisationAutomation from "../images/use-cases/revops.png"

import CTA from "../components/cta"
import GoCardlessLogo from "../images/GoCardless_Wordmark_Regular_Neg_Dawn.svg"
import dataCustomisationPng from "../images/data-customisation.png"

import {
  IconBulb,
  IconCalculator,
  IconChevronDown,
  IconEdit,
  IconReplace,
  IconSpeedboat,
  IconTag,
  IconTree,
  IconTypography,
  IconWriting,
} from "@tabler/icons"
import HeadlessCTA from "../components/headlessCTA"
import { Link } from "gatsby"
import LogoWall from "../components/LogoWall"

const AccordionItem = ({ id, title, highlight, content }) => {
  return (
    <Accordion.Item value={id} className="py-8 text-white">
      <Accordion.Header>
        <Accordion.Trigger
          className="data-[state=closed]:saturate-0 data-[state=closed]:hover:saturate-100 
          data-[state=closed]:opacity-25 data-[state=closed]:hover:opacity-100 
          flex items-center gap-5 transition-all AccordionTrigger w-full"
        >
          <span className="text-green-500 text-2xl -ml-8">&bull;</span>
          <h4 className="text-3xl hover:text-blue transition">{title}</h4>
          <div className="flex-1"></div>
          <div className="AccordionChevron transition">
            <IconChevronDown />
          </div>
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <div className="flex flex-col lg:flex-row">
          <div className="flex-1">
            <p className="text-xl leading-relaxed text-medium-grey/75 py-8">
              <span className="text-lilac">{highlight} </span>&mdash; {content}
            </p>
            <a className="text-blue hover:underline" href="#cta">
              See the product &rarr;
            </a>
          </div>
          <div className="flex-1"></div>
        </div>
      </Accordion.Content>
    </Accordion.Item>
  )
}

const ProductOverview = () => {
  return (
    <main>
      <section className="flex flex-col items-center pt-24 px-4  bg-gradient-to-b from-lilac-15">
        <div class="flex flex-col gap-4 max-w-2xl lg:max-w-6xl text-center">
          <h2 class="font-bold text-4xl lg:text-6xl xl:text-8xl flex-1 leading-tight text-white z-10">
            What Tactic enables
          </h2>
          <p class="text-dark-grey">
            Customisable data helps you capture granular account and prospect
            attributes to better target your sales, marketing and customer
            success.
          </p>
        </div>
      </section>

      {/* Target Accounts */}

      <section className="flex flex-col md:flex-row my-24" id="targeting">
        <div className="flex-1 flex items-center">
          <div className="ml-auto max-w-3xl md:py-36 pl-4 xl:pl-0 pr-16">
            <div className="text-lilac uppercase tracking-widest">
              Targeting automation
            </div>
            <h3 className="text-3xl lg:text-5xl leading-tight my-4 lg:my-8 font-semibold text-white">
              Better target accounts
            </h3>
            <div className="text-sm lg:text-xl text-soft-tones-blue mb-4 flex flex-col gap-4">
              <p>
                Source every account, team, initiative and prospect in your TAM.
                Clean and enrich them with a customised, unlimited selection of
                data.
              </p>
              <p>Score, qualify and segment every company on the planet. </p>
              <p>
                Distribute the right segments to the right channel: inbound,
                outbound, partners, product-led and more.
              </p>
              <div className="text-base flex gap-4 py-4 text-dark-grey">
                <IconReplace /> Replaces self sourcing accounts,
                ZoomInfo/Crunchbase/BVD, data outsourcing, data quality
                projects.
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="gradient-background xl:rounded-lg mr-auto max-w-3xl relative h-[300px] md:h-full overflow-hidden">
            <img
              src={TargetingAutomation}
              className="shadow-2xl absolute top-12 left-12 min-w-[1080px] rounded-lg"
            />
          </div>
        </div>
      </section>

      <section className="px-4">
        <div className="container mx-auto flex flex-col md:flex-row text-white gap-12">
          <div className="flex-1">
            <IconWriting className="text-blue mb-4" />
            <h5 className="text-lg">Custom industries</h5>
            <p className="text-sm text-dark-grey">
              Classify companies into any industry you specify. Separate 'SaaS'
              from 'IT consultancies', 'Banks' from 'Fintech'.
            </p>
          </div>
          <div className="flex-1">
            <IconTag className="text-blue mb-4" />
            <h5 className="text-lg">Smart company tags</h5>
            <p className="text-sm text-dark-grey">
              Tag companies based on their website. Get hyper specific tags like
              'NFT marketplace', 'Generative AI'.
            </p>
          </div>
          <div className="flex-1">
            <IconBulb className="text-blue mb-4" />
            <h5 className="text-lg">Web Answers</h5>
            <p className="text-sm text-dark-grey">
              Enrich companies with any answer you can Google. Revenue. Market
              share. Number of customers.
            </p>
          </div>
          <div className="flex-1">
            <IconCalculator className="text-blue mb-4" />
            <h5 className="text-lg">Calculations</h5>
            <p className="text-sm text-dark-grey">
              Combine data into useful metrics. % of a company working in
              software engineering etc.
            </p>
          </div>
        </div>
      </section>

      {/* Engagement Insights */}

      <section
        className="flex flex-col-reverse md:flex-row my-24 border-t border-white/10 py-12"
        id="personalisation"
      >
        <div className="flex-1">
          <div className="gradient-background xl:rounded-lg ml-auto max-w-3xl relative h-[300px] md:h-full overflow-hidden">
            <img
              src={EngagementAutomation}
              className="shadow-2xl absolute top-12 -left-48 min-w-[1080px] rounded-lg"
            />
          </div>
        </div>
        <div className="flex-1 flex items-center">
          <div className="mr-auto max-w-3xl px-6 sm:px-12 md:py-36">
            <div className="text-lilac uppercase tracking-widest">
              Engagement automation
            </div>
            <h3 className="text-3xl lg:text-5xl leading-tight my-4 lg:my-8 font-semibold text-white">
              Personalise every buyer interaction
            </h3>
            <div className="text-sm lg:text-xl text-soft-tones-blue mb-4 flex flex-col gap-4">
              <p>
                Prioritise accounts based on propensity to buy, ACV, triggers
                and intent.
              </p>
              <p>
                Engage them using detailed insights on goals, pain points, and
                initiatives of each prospect.
              </p>
              <p>
                Close more revenue by building relationships with the entire
                buying committee and connecting your solution to the buyers'
                strategy.
              </p>
              <div className="text-base flex gap-4 py-4 text-dark-grey">
                <IconReplace /> Replaces scraping event lists, media monitoring,
                ABM agencies, manual account plans and maps, territory
                prioritisation spreadsheets.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="px-4">
        <div className="container mx-auto flex text-white gap-12">
          <div className="flex-1">
            <IconEdit className=" text-amber mb-4" />
            <h5 className="text-lg">Free text triggers</h5>
            <p className="text-sm text-dark-grey">
              Use free text search to make sales triggers. Find M&A events, new
              CFOs, and more.
            </p>
          </div>
          <div className="flex-1">
            <IconTree className="text-amber mb-4" />
            <h5 className="text-lg">Show the source</h5>
            <p className="text-sm text-dark-grey">
              Any insight that Tactic surfaces will have a source. You can see
              exactly where in the raw data did we find your signal.
            </p>
          </div>
          <div className="flex-1">
            <IconBulb className="text-amber mb-4" />
            <h5 className="text-lg">Single source of intelligence</h5>
            <p className="text-sm text-dark-grey">
              Put relevant details, contacts, and search results about the
              company you are engaging on one screen.
            </p>
          </div>
        </div>
      </section>

      {/* Optimisation automation */}

      <section className="flex flex-col md:flex-row my-24" id="iteration">
        <div className="flex-1 flex items-center">
          <div className="ml-auto max-w-3xl px-6 sm:px-12 md:py-36">
            <div className="text-lilac uppercase tracking-widest">
              Optimisation automation
            </div>
            <h3 className="text-3xl lg:text-5xl leading-tight my-4 lg:my-8 font-semibold text-white">
              Apply learnings quickly
            </h3>
            <div className="text-sm lg:text-xl text-soft-tones-blue mb-4 flex flex-col gap-4">
              <p>
                Replicate high performing reps and campaigns. Find new
                attributes and signals to increase win rates and ACV. Deploy
                findings into your territory plans, marketing strategies and
                sales tactics.
              </p>
              <div className="text-base flex gap-4 py-4 text-dark-grey">
                <IconSpeedboat /> Accelerates strategic revenue operations
                projects, territory planning and book building.
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="gradient-background xl:rounded-lg mr-auto max-w-3xl relative h-[300px] md:h-full overflow-hidden">
            <img
              src={OptimisationAutomation}
              className="shadow-2xl absolute top-12 left-12 min-w-[1080px] rounded-lg"
            />
          </div>
        </div>
      </section>
    </main>
  )
}

const Platform = () => {
  return (
    <Layout variant="dataPoint">
      <SEO
        title="Go-to-market data automation"
        description="Deliver personalised buyer experiences and free up your data team from complicated queries with our go-to-market data automation."
      />

      <div className="container mx-auto">
        <div className="flex flex-col gap-8 my-12 px-6" id="hero-container">
          <h1
            className="font-bold text-white text-2xl md:text-4xl lg:text-7xl max-w-5xl"
            style={{
              mixBlendMode: "overlay",
              fontFeatureSettings: "'ss02' on, 'ss03' on, 'ss01' on",
            }}
          >
            Make your own sales intelligence
          </h1>
          <h2
            className="text-white lg:text-xl max-w-4xl"
            style={{ fontFeatureSettings: "'salt' on" }}
          >
            In this market, you can't afford to guess. Get customised data on
            your prospects and scale revenue efficiently.
          </h2>
          <HeadlessCTA />
        </div>

        <div className="relative group max-w-5xl ml-auto">
          <img src={DemoSplash} />
        </div>
      </div>

      <div className="flex justify-center w-screen my-12 border-t border-b border-white/10">
        <LogoWall />
      </div>

      <div className="container mx-auto flex flex-col md:flex-row gap-12 px-4 md:py-16">
        <div className="flex-1 md:w-5/12">
          <div className="text-white font-light text-3xl lg:text-6xl my-12">
            What is customised data?
          </div>
          <div className="text-dark-grey lg:text-xl">
            <div className="pb-4">
              Data vendors are one-sized-fits-none. Simple factors like HQ,
              industry, and headcount are not specific enough to target the
              right customer.{" "}
            </div>
            <div className="pb-4">
              Tactic enables you to build out customised company data in a few
              clicks, perfectly matching your unique ideal customers.
            </div>
          </div>
        </div>
        <div className="flex-1 md:w-7/12">
          <img src={dataCustomisationPng} />
        </div>
      </div>

      <section className=" bg-black py-24 px-4">
        <div className="container mx-auto flex flex-col items-start">
          <div class="flex flex-col gap-4 max-w-2xl lg:max-w-4xl mb-12">
            <h2 class="font-bold text-4xl lg:text-6xl flex-1 leading-tight text-white z-10">
              How it works
            </h2>
            <p class="text-dark-grey text-xl">
              Tactic stores a live copy of the internet, your Salesforce, and
              3rd party API data. You can then extract customised data in a few
              clicks, automating manual research, consolidate data vendors, and
              eliminate ad hoc scraping.
            </p>
          </div>
          <Accordion.Root
            type="single"
            defaultValue="item-1"
            className="divide-y divide-dark-grey/25 my-8 flex-1"
          >
            <AccordionItem
              id="item-1"
              title="Get a 360° picture of your market"
              highlight="Tactic unifies many raw data sources"
              content="company databases, the internet, job postings, annual reports, and more — basically anywhere your customers write about themselves. It saves your team valuable time."
            />
            <AccordionItem
              id="item-2"
              title="Highlight what really matters to your go-to-market"
              highlight="Use LLMs and AI to extract key data points from unstructured customer data"
              content="firmographics, tech usage, business models, funding, team distribution, key initiatives. You can customise these data points to fit your business perfectly."
            />
            <AccordionItem
              id="item-3"
              title="Right target, right message, right time"
              highlight="Make data your competitive advantage"
              content="Prioritise the right markets, and arm your team with the best audiences and territories, as well as who to target, when and why."
            />
          </Accordion.Root>
          <img src={ProductFlowChart} className="my-8 px-4 mx-auto" />
        </div>
      </section>

      <ProductOverview />

      <section className="border-b border-t border-white/10 py-24 my-12 px-4">
        <div className="container mx-auto flex flex-col lg:flex-row gap-24 items-start px-4">
          <div class="flex flex-col gap-4 flex-1">
            <h2 class="font-bold text-4xl lg:text-6xl flex-1 leading-tight text-white z-10">
              Results: why revenue teams choose Tactic
            </h2>
            <div className="flex flex-col divide-y divide-white/20 my-12">
              <div className="text-soft-tones-lime flex justify-between items-center py-4">
                <div className="text-3xl lg:text-6xl">+40%</div>
                <div className="text-xl">pipeline efficiency</div>
              </div>
              <div className="text-soft-tones-blue flex justify-between items-center py-4">
                <div className="text-3xl lg:text-6xl">+79%</div>
                <div className="text-xl">deal size</div>
              </div>
              <div className="text-soft-tones-amber flex justify-between items-center py-4">
                <div className="text-3xl lg:text-6xl">10+ hrs</div>
                <div className="text-xl">saved per rep</div>
              </div>
            </div>
            <Link className="text-blue" to="/gocardless">
              Read customer stories here &rarr;
            </Link>
          </div>
          <div className="flex-1 bg-white/5 border border-white/10 rounded-lg p-8 lg:p-24 text-white">
            <img src={GoCardlessLogo} className="mb-12 w-72" />
            <div className="text-xl lg:text-3xl lg:leading-10 mb-12">
              "Tactic is truly an extension of our data capability, and enables
              us to deliver high quality data to sales, marketing, customer
              success, partnerships, and more with a small team."
            </div>
            <div>
              &mdash; Jody Clark, Global Vice President of Sales Development
            </div>
          </div>
        </div>
      </section>

      <div id="cta">
        <CTA />
      </div>
    </Layout>
  )
}

export default Platform
